// authPostMessage.js

// ------------------------------
// SEND MESSAGE TO CONTENT SCRIPT
// ------------------------------

/* export function sendMessageToContentScript(data) {
  console.log("Sending message to content script:", data);

  // Serialize the data into a JSON string and send it to a specific origin
  window.postMessage(
    JSON.stringify(data),
    "https://ramauthtest.firebaseapp.com"
  );

  // Log after the message is sent
  console.log("postMessage sent with data:", data);
}
 */

// authPostMessage.js

// ------------------------------
// SEND MESSAGE TO CONTENT SCRIPT
// ------------------------------

export function sendMessageToContentScript(data) {
  // Serialize the data into a JSON string and send it to a specific origin
  window.postMessage(JSON.stringify(data), "https://app.s-kode.com");

  // Log after the message is sent
  console.log("User login sent to AutoCV extension:", data);
}
