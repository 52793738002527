// dbMakeDelete.js

import $ from "jquery";
import { getAuth } from "firebase/auth";

// Set up Firebase Authentication reference
const auth = getAuth(); // Get the Firebase Auth instance

// Function to set up delete button event listeners
export function setupDeleteMakeButtons() {
  // Find all buttons with the class "delete-btn" and attach an event listener
  $(".delete-btn").each(function () {
    $(this).on("click", async function () {
      // When a delete button is clicked, retrieve the necessary data attributes
      const docId = $(this).attr("data-doc-id");

      // Get the current user's email (since we need to send it with the webhook)
      const user = auth.currentUser;
      if (user) {
        const userEmail = user.email;
        const $row = $(this).closest("tr"); // Find the parent row of the clicked delete button

        // Call the function to send the delete request webhook
        const success = await sendDeleteWebhook(docId, userEmail);

        // If the webhook is successful, hide/remove the row
        if (success) {
          $row.hide(); // Or you can use $row.remove() to completely remove it
          console.log(`Row for docId ${docId} hidden`);
        } else {
          console.error("Delete webhook failed, row will not be removed.");
        }
      } else {
        console.error("No authenticated user found.");
      }
    });
  });
}

const webhookUrl = process.env.WEBOOK_MAKE_DELETE;

// Function to send the delete webhook to n8n
async function sendDeleteWebhook(docId, userEmail) {
  try {
    const response = await fetch(webhookUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        docId: docId, // Send the document ID
        email: userEmail, // Send the user's email
      }),
    });

    if (response.ok) {
      // console.log("Delete webhook sent successfully.");
      return true; // Webhook succeeded
    } else {
      console.error("Failed to send delete webhook. Status: ", response.status);
      return false; // Webhook failed
    }
  } catch (error) {
    console.error("Error sending delete webhook:", error);
    return false; // Webhook failed due to an error
  }
}
