// /src/js/auth.js

// Firebase Imports
import { app, db } from "./firebaseConfig.js";
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";

// Firestore Imports
import { getDoc, doc, setDoc } from "firebase/firestore";

// Webhooks Imports
import { triggerSignUpWorkflow } from "./authWebhookSignup.js";

// Import auth functions
// import { setupLogoutListener } from "./authUserLogout.js";
// import { setupDeleteAccountListener } from "./authUserDelete.js";
import { sendMessageToContentScript } from "./authPostMessage.js";

// Initialize FireStore/Firebase services
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Initialize authentication and setup event listeners
document.addEventListener("DOMContentLoaded", function () {
  initializeAuth()
    .then(() => {
      // setupGoogleSignIn();
      handleRedirectResult();
      // setupLogoutListener(); // Initialize logout button
      // setupDeleteAccountListener(); // Initialize delete account button
    })
    .catch((error) => {
      console.error("Error setting session persistence:", error);
    });
});

// -------------------------
// FIREBASE AUTH
// -------------------------

function initializeAuth() {
  // Set session-based persistence
  return setPersistence(auth, browserSessionPersistence);
}

export function setupGoogleSignIn() {
  const googleSignInButton = document.getElementById("google-sign-in");

  // Check if the button exists before attaching the event listener
  if (googleSignInButton) {
    googleSignInButton.addEventListener("click", () => {
      signInWithRedirect(auth, googleProvider);
    });
  } else {
    // console.log("Google Sign-In button not found.");
  }
}

// pre-check for button existing
/* function setupGoogleSignIn() {
  // Sign-In button event listener — signInWithRedirect after persistence is set
  document.getElementById("google-sign-in").addEventListener("click", () => {
    signInWithRedirect(auth, googleProvider);
  });
} */

function handleRedirectResult() {
  // Handle sign-in result after redirect
  getRedirectResult(auth)
    .then((result) => {
      if (result) {
        const currentUser = result.user;
        handleSignIn(currentUser);
      }
    })
    .catch((error) => {
      console.error("Error processing sign-in result:", error);
    });
}

// -------------------------
// FIRESTORE OPERATIONS
// -------------------------

// Function to save user data in Firestore
async function saveUserData(currentUser) {
  const userData = prepareUserData(currentUser);

  await setDoc(doc(db, "users", currentUser.email), userData, { merge: true });
}

// Function to prepare user data for saving in Firestore
function prepareUserData(currentUser) {
  const displayName = currentUser.displayName || "";
  const names = displayName.split(" ");
  const firstName = names[0] || "";
  const lastName = names.slice(1).join(" ") || "";
  const lastLogin = new Date(); // Get current date and time

  return {
    email: currentUser.email,
    firstName: firstName,
    lastName: lastName,
    lastLogin: lastLogin,
  };
}

// Firestore: Check if userFiles/folderData exists
async function checkUserFilesDocumentExists(currentUser) {
  const userFilesDocRef = doc(
    db,
    `users/${currentUser.email}/userFiles/folderData`
  );
  const userFilesDocSnap = await getDoc(userFilesDocRef);
  return userFilesDocSnap.exists();
}

// -------------------------
// USER ACCOUNT MANAGEMENT
// -------------------------

// Function to handle userFiles check and trigger sign-up workflow
async function handleUserFilesCheck(currentUser) {
  const documentExists = await checkUserFilesDocumentExists(currentUser);

  if (!documentExists) {
    await triggerSignUpWorkflow({ email: currentUser.email });
    console.log(
      "Sign-up workflow triggered successfully (as userFiles/folderData did not exist)."
    );
  } else {
    console.log(
      "User subcollection document exists, no need to trigger sign-up workflow."
    );
  }
}

// Function to handle sign-in with postMessage
function handleSignIn(currentUser) {
  try {
    saveUserData(currentUser);
    handleUserFilesCheck(currentUser);
  } catch (error) {
    console.error("Error handling sign-in:", error);
  }
}
