// dbGetUserBaseResumes.js

import {
  getFirestore,
  collection,
  getDocs,
  query,
  doc,
  getDoc,
} from "firebase/firestore";
import { app } from "./firebaseConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import $ from "jquery";

import { setupSetDefaultResumeButtons } from "./dbUpdateDefaultResume";
import { setupDeleteBaseResumeButtons } from "./dbDeleteBaseResume";

import googleDocsIcon from "../images/icon-google-docs.png";

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Fetches user base resumes from Firestore along with the default resume ID
export async function fetchUserBaseResumes(email) {
  const userDocRef = doc(db, `users/${email}`);
  const userBaseResumesRef = collection(db, `users/${email}/userBaseResumes`);
  const q = query(userBaseResumesRef);

  const userDocSnapshot = await getDoc(userDocRef);
  const defaultBaseResumeId = userDocSnapshot.data()?.defaultBaseResume; // Get default resume ID

  const querySnapshot = await getDocs(q);
  let userBaseResumes = [];
  querySnapshot.forEach((doc) => {
    let resumeData = {
      id: doc.id,
      ...doc.data(),
      isDefault: doc.id === defaultBaseResumeId,
    };
    userBaseResumes.push(resumeData);
  });
  return userBaseResumes;
}

export function createModuleContainerBaseResumes() {
  // Remove any pre-existing moduleContainer divs
  $("#moduleContainerBaseResumes").remove();

  // Create the module container div
  $("#userBaseResumesPlaceholder").append(
    $("<div>")
      .attr("id", "moduleContainerBaseResumes")
      .css("opacity", "100")
      .append(
        $("<h2>")
          .text("User Base Resumes")
          .attr("title", "Click to show/hide.")
          .addClass("dashboard-module-header")
          .css("cursor", "pointer") // Clickable
          .on("click", function () {
            // Toggle visibility of both the table and the button
            $("#userBaseResumesTable").toggle();
            $("#btn-setup-resume").toggle(); // Toggle the button visibility
          })
      )

      .append(
        $("<div>").append(
          $("<table>")
            .attr("id", "userBaseResumesTable")
            .addClass("user-makes-table")
            .append(
              $("<tr>")
                .append($("<th>").text("DATE"))
                .append($("<th>").text("NAME"))
                .append($("<th>").text("GDOC"))
                .append($("<th>").text("DEFAULT?"))
                .append($("<th>").text("SET DEFAULT"))
                .append($("<th>").text("STATUS"))
                .append($("<th>").text("DELETE"))
            )
        )
      )
  );

  // Initially hide the table content (optional)
  $("#userBaseResumesTable").hide();
}

export function displayUserBaseResumes(userBaseResumes) {
  const $table = $("#userBaseResumesTable").css("display", "table");
  $table.find("tr:gt(0)").remove(); // Clear existing rows except for the header

  // Check if there are no base resumes
  if (userBaseResumes.length === 0) {
    const $noResumesRow = $("<tr>").append(
      $("<td>")
        .attr("colspan", 7) // Span across all columns
        .css("text-align", "center") // Center the text
        .text("No base resumes found.")
    );
    $table.append($noResumesRow);
    return; // Exit the function early
  }

  userBaseResumes.forEach((resume) => {
    const $row = $("<tr>").addClass("data-row");

    // DATE: Convert UNIX timestamp to date
    const resumeDate = new Date(parseInt(resume.makeDateTimeUNIX));
    const formattedDate = resumeDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Append formatted date to the row
    $row.append($("<td>").text(formattedDate));

    // NAME
    $row.append($("<td>").text(resume.baseResumeName));

    // LINK TO GDOC
    $row.append(
      $("<td>").append(
        $("<a>")
          .attr({
            href: `https://docs.google.com/document/d/${resume.baseResumeFileIDGDoc}`,
            target: "_blank",
          })
          .append(
            $("<img>")
              .attr("src", googleDocsIcon)
              .attr("alt", "Google Docs Icon")
              .addClass("icon")
          )
      )
    );

    // DEFAULT?
    $row.append(
      $("<td>")
        .text(resume.isDefault ? "•" : "") // Display a bullet point if it's the default
        .addClass("base-resume-default-check")
        .attr("data-doc-id", resume.id)
    );

    // SET DEFAULT BUTTON
    $row.append(
      $("<td>").append(
        $("<button>")
          .text("Default")
          .addClass("user-makes-btn set-default-btn")
          .attr("data-doc-id", resume.id)
      )
    );

    // STATUS
    $row.append($("<td>").text(resume.makeStatus));

    // DELETE BUTTON
    $row.append(
      $("<td>").append(
        $("<button>")
          .text("Delete")
          .addClass("user-makes-btn delete-base-resume-btn")
          .attr("data-doc-id", resume.id)
      )
    );

    $table.append($row);
  });

  // Set up buttons for additional functionality
  setupSetDefaultResumeButtons();
  setupDeleteBaseResumeButtons();
}

// Listen for auth state changes and fetch user base resumes
onAuthStateChanged(auth, async (user) => {
  const modulePlaceholder = document.getElementById(
    "userBaseResumesPlaceholder"
  );

  if (user) {
    const userBaseResumes = await fetchUserBaseResumes(user.email);
    createModuleContainerBaseResumes();
    displayUserBaseResumes(userBaseResumes);
  } else {
    // No user is logged in
    console.log("No user is logged in.");
    modulePlaceholder.style.display = "none";
  }
});
