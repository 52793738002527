// dbGetUserMakes.js

import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
  query,
} from "firebase/firestore";
import { app } from "./firebaseConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import $ from "jquery";

import { setupDeleteMakeButtons } from "./dbDeleteMake";
import { setupMakeCoverLetterButtons } from "./dbMakeCoverLetter";

import googleDriveIcon from "../images/icon-google-drive.png";
import pdfIcon from "../images/icon-pdf.png";

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Fetches user makes from Firestore
async function fetchUserMakes(email) {
  const userMakesRef = collection(db, `users/${email}/userMakes`);

  // PROMPT TO DO
  // Add a new variable `userMakesFolderRef` to get the value from the `users/${email}/userFiles/folderData` document from field `userFolderMakes`
  const userFilesDocRef = doc(db, `users/${email}/userFiles/folderData`);
  const userFilesDoc = await getDoc(userFilesDocRef);
  const userFolderMakes = userFilesDoc.exists()
    ? userFilesDoc.data().userFolderMakes
    : null;

  const q = query(userMakesRef); // Add any necessary queries or filters
  const querySnapshot = await getDocs(q);
  let userMakes = [];
  querySnapshot.forEach((doc) => {
    userMakes.push({ id: doc.id, ...doc.data() });
  });

  return { userMakes, userFolderMakes }; // Return both userMakes and userFolderMakes
}

function createModuleContainer() {
  // Create the module container div
  $("#userMakesPlaceholder").append(
    $("<div>")
      .attr("id", "moduleContainer")
      .css("opacity", "100") // You can uncomment display: none if you want to hide it initially
      .append(
        $("<h2>")
          .text("User Makes")
          .attr("title", "Show/Hide")
          .addClass("dashboard-module-header")
          .css("cursor", "pointer") // Clickable
          .on("click", function () {
            // Toggle visibility
            $("#userMakesTable").toggle();
          })
      ) // Add heading
      .append(
        $("<div>").append(
          $("<table>")
            .attr("id", "userMakesTable")
            .addClass("user-makes-table")
            .append(
              $("<tr>")
                .append($("<th>").text("DATE"))
                .append($("<th>").text("JOB").css("text-align", "center"))
                .append($("<th>").text("GD"))
                .append($("<th>").text("PDF"))
                .append($("<th>").text("STATUS"))
                .append(
                  $("<th>")
                    .text("COVER LETTER")
                    .css("font-size", "11px")
                    .css("text-align", "center")
                )
                .append($("<th>").text("DELETE"))
            )
        )
      )
      .append($("<div>").attr("id", "userMakesContainer")) // Empty container
  );
}

function displayUserMakes(userMakes, userFolderMakes) {
  const $table = $("#userMakesTable").css("display", "table");

  // Clear existing rows except for the header
  $table.find("tr:gt(0)").remove();

  // Check if there are no userMakes
  if (userMakes.length === 0) {
    const $noMakesRow = $("<tr>").append(
      $("<td>")
        .attr("colspan", 7) // Span across all columns
        .css("text-align", "center") // Center the text
        .text("No makes found.")
    );
    $table.append($noMakesRow);
    return;
  }

  // Sort userMakes by makeDateTimeUNIX in descending order (newest first)
  userMakes.sort((a, b) => b.makeDateTimeUNIX - a.makeDateTimeUNIX);

  let previousDate = null;

  userMakes.forEach((make) => {
    const currentDate = new Date(make.makeDateTimeUNIX);
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const dayKey = currentDate.toDateString(); // Unique key for each day

    // Check if we need to add a new row for the date (if the day has changed)
    if (
      !previousDate ||
      currentDate.toDateString() !== previousDate.toDateString()
    ) {
      // Create an empty spacer row before the date row
      const $spacerRow = $("<tr>")
        .addClass("spacer-row")
        .append($("<td>").attr("colspan", 7).css("padding", "4px"));

      // Append the spacer row to the table
      $table.append($spacerRow);

      // Create the actual date header row
      const $dateRow = $("<tr>")
        .addClass("date-header")
        .attr("data-day", dayKey) // Add unique identifier for this day
        .append(
          $("<td>")
            .attr("colspan", 7) // Span across all columns
            .attr("title", "Show/Hide")
            .addClass("date-header")
            .text(formattedDate)
        );

      // Append the date row to the table
      $table.append($dateRow);
    }

    previousDate = currentDate;

    const $row = $("<tr>").addClass("data-row").attr("data-day", dayKey); // Add matching identifier to group with the date header
    // .css("display", "none"); // Initially hidden

    // DATE
    $row.append($("<td>").text(currentDate.toLocaleDateString()));

    // TITLE as a clickable link to makeJobPostURL
    $row.append(
      $("<td>")
        .addClass("track-app-td-job")
        .css("text-align", "left") // Inline CSS to align text to the left
        .append(
          $("<a>")
            .attr({
              href: make.makeJobPostURL,
              target: "_blank",
            })
            .text(make.makeTitle)
        )
    );

    // GDRIVE LINK
    $row.append(
      $("<td>").append(
        $("<a>")
          .attr({
            href: `https://drive.google.com/drive/folders/${make.makeFileIDMakeFolder}`,
            target: "_blank",
          })
          .append(
            $("<img>")
              .attr("src", googleDriveIcon)
              .attr("alt", "Google Drive Icon")
              .addClass("icon")
          )
      )
    );

    // PDF LINK
    $row.append(
      $("<td>").append(
        $("<a>")
          .attr({
            href: `https://drive.google.com/file/d/${make.makeFileIDMakeResumePDF}`,
            target: "_blank",
          })
          .append(
            $("<img>")
              .attr("src", pdfIcon)
              .attr("alt", "PDF Icon")
              .addClass("icon")
          )
      )
    );

    // MAKE STATUS
    $row.append($("<td>").text(make.makeStatus));

    // MAKE CL
    $row.append(
      $("<td>").append(
        $("<button>")
          .text("Make")
          .addClass("user-makes-btn make-cl-btn")
          .attr("data-doc-id", make.id)
      )
    );

    // DELETE BUTTON
    $row.append(
      $("<td>").append(
        $("<button>")
          .text("Delete")
          .addClass("user-makes-btn delete-btn")
          .attr("data-doc-id", make.id)
      )
    );

    $table.append($row);
  });

  // ALL USER MAKES BUTTON
  // PROMPT TO DO: Add an onClick event to open the userMakesFolderRef in a new tab
  const $allUserMakesBtn = $("<button>")
    .addClass("user-makes-btn-all")
    .append(
      $("<span>").addClass("user-makes-btn-all-span").text("All User Makes")
    )
    .append(
      $("<span>")
        .addClass("user-makes-btn-all-span")
        .append(
          $("<img>")
            .attr("src", googleDriveIcon)
            .attr("alt", "Google Drive Icon")
            .addClass("icon")
        )
    )
    .on("click", function () {
      if (userFolderMakes) {
        window.open(
          `https://drive.google.com/drive/folders/${userFolderMakes}`,
          "_blank"
        );
      } else {
        alert("No folder found for user makes.");
      }
    });

  $("#userMakesPlaceholder").append($allUserMakesBtn);

  // Call function to set up buttons
  setupMakeCoverLetterButtons();
  setupDeleteMakeButtons();

  // Add click handler to toggle rows for each day
  $(".date-header").on("click", function () {
    const day = $(this).data("day"); // Get the day associated with the header
    $(`.data-row[data-day='${day}']`).toggle(); // Toggle visibility of rows with the same day
  });
}

// Listen for auth state changes and fetch user makes
onAuthStateChanged(auth, async (user) => {
  const moduleContainer = document.getElementById("userMakesPlaceholder");

  if (user) {
    const { userMakes, userFolderMakes } = await fetchUserMakes(user.email);
    createModuleContainer();
    displayUserMakes(userMakes, userFolderMakes);
    // console.log("Displaying user makes");
  } else {
    // No user is logged in
    console.log("No user is logged in.");
    moduleContainer.style.display = "none";
  }
});
