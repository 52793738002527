// dbGetUser.js

import { getFirestore, doc, getDoc, collection } from "firebase/firestore";
import { app } from "./firebaseConfig.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import $ from "jquery";

import { setupGoogleSignIn } from "./auth.js";
import { setupLogoutListener } from "./authUserLogout.js";
import { sendMessageToContentScript } from "./authPostMessage.js";
// import { setupDeleteAccountListener } from "./authUserDelete.js";

// ------------------------------
// FIRESTORE
// ------------------------------

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication and Firestore
const auth = getAuth(app);

// Fetches data from Firestore
async function fetchUserGetData(email) {
  // Firestore document references
  const folderDataRef = doc(db, `users/${email}/userFiles/folderData`);
  // const baseResumesRef = doc(db, `users/${email}/userBaseResumes/1725844511`); // TODO: update to get subcollection list, or default resume from user doc
  const userUsageTotalSumsRef = doc(
    db,
    `users/${email}/userUsage/userUsageTotalSums`
  );

  // Fetch folderData and baseResumes documents
  const folderDataSnap = await getDoc(folderDataRef);
  // const baseResumesSnap = await getDoc(baseResumesRef);

  // Fetch the user usage total sums document
  const userUsageTotalSumsSnap = await getDoc(userUsageTotalSumsRef);

  let totalTokensUsed = 0;
  let totalCreditsUsed = 0;
  let totalCreditsRemaining = 0;

  // Check if the userUsageTotalSums document exists and contains the sum fields
  if (userUsageTotalSumsSnap.exists()) {
    const userUsageTotalSumsData = userUsageTotalSumsSnap.data();
    totalTokensUsed = userUsageTotalSumsData.userUsageTotalTokensSum || 0;
    totalCreditsUsed = userUsageTotalSumsData.userUsageTotalCreditsSum || 0;
    totalCreditsRemaining =
      userUsageTotalSumsData.userUsageTotalCreditsRemaining || 0;
  }

  return {
    folderData: folderDataSnap.exists() ? folderDataSnap.data() : null,
    // baseResumeData: baseResumesSnap.exists() ? baseResumesSnap.data() : null,
    totalTokensUsed,
    totalCreditsUsed,
    totalCreditsRemaining,
  };
}

// Processes and stores fetched data
function storeUserGetData(userData) {
  const {
    folderData,
    baseResumeData,
    totalTokensUsed,
    totalCreditsUsed,
    totalCreditsRemaining,
  } = userData;

  // Extract user folder makes and base resume GDoc links from the fetched data
  const userFolderMakes = folderData ? folderData.userFolderMakes || "" : "";
  const baseResumeGDoc = baseResumeData
    ? baseResumeData.baseResumeGDoc || ""
    : "";

  // Return the processed data
  return {
    userFolderMakes,
    baseResumeGDoc,
    totalTokensUsed,
    totalCreditsUsed,
    totalCreditsRemaining,
  };
}

// ------------------------------
// UI
// ------------------------------

// Display Sign-In Button
export async function displaySignInButton() {
  const $userGetPlaceholder = $("#userGetPlaceholder");

  // Remove any existing userGetContainer to avoid duplicates
  $("#userGetContainer").remove();
  $("#userGetHr").remove();

  // Logout Button
  $userGetPlaceholder.append(
    $("<button>")
      .attr("id", "google-sign-in") // Add the id attribute
      .addClass("user-get-btn-google-sign-in")
    // .text("Google Sign-In")
  );

  setupGoogleSignIn();
}

// Display userGet data
export async function displayUserGetData(email) {
  const userEmail = email || "user@email.com"; // Default if no email provided
  const fetchedData = await fetchUserGetData(userEmail);
  const processedData = storeUserGetData(fetchedData);
  const userGetPlaceholder = $("#userGetPlaceholder");

  // Remove any existing userGetContainer to avoid duplicates
  $("#userGetContainer").remove();

  // Create the userGetContainer div dynamically and append it to mainContainer
  const $userGetContainer = $("<div>")
    .attr("id", "userGetContainer")
    .addClass("user-get-container")
    .css("display", "flex");

  userGetPlaceholder.append($userGetContainer);

  // User Email
  $userGetContainer.append(
    $("<span>").addClass("user-get-span").text(userEmail)
  );

  // Tokens Used and Credits Used on the same line with text-align left
  $userGetContainer.append(
    $("<span>")
      .addClass("user-get-span")
      // .css("text-align", "left")
      .append(
        // Credits Remaining
        $("<span>").text(`Credits Used: ${processedData.totalCreditsUsed}`),
        // Tokens Used
        // $("<span>").text(`Tokens Used: ${processedData.totalTokensUsed}`),
        // Credits Used
        // $("<span>").text(`Credits Used: ${processedData.totalCreditsUsed}`),
        console.log(
          "Tokens Used: ",
          processedData.totalTokensUsed,
          "| Credits Used: ",
          processedData.totalCreditsUsed
        )
      )
  );

  // User Makes Folder (GDrive)
  /* $userGetContainer.append(
    $("<button>")
      .addClass("user-get-btn")
      .text("Makes")
      .on("click", function () {
        window.open(
          `https://drive.google.com/drive/folders/${processedData.userFolderMakes}`,
          "_blank"
        );
      })
  ); */

  // Download Extension
  $userGetContainer.append(
    $("<button>")
      .addClass("user-get-btn")
      .text("Install Extension")
      .on("click", function () {
        window.open(
          `https://chromewebstore.google.com/detail/autocv/micjohkmnpfkcnfoifijfdffpcajjiod`,
          "_blank"
        );
      })
  );

  // Logout Button
  $userGetContainer.append(
    $("<button>")
      .attr("id", "logout") // Add the id attribute
      .addClass("user-get-btn")
      .text("Logout")
  );

  $("#userGetPlaceholder").append($("<hr>").attr("id", "userGetHr"));

  setupLogoutListener();
}

// ------------------------------
// AUTH STATE CHANGE
// ------------------------------

// Listen for auth state changes
/* onAuthStateChanged(auth, async (user) => {
  const email = user?.email || "user@email.com"; // Use a fallback email

  if (user) {
    console.log(`User: ${email}`);
    displayUserGetData(email);
    // console.log("Displaying userGetData");
    // setupLogoutListener();
    const email = user?.email || "user@email.com";

    console.log("Sending message to content script.", currentUser.email);
    sendMessageToContentScript({
      type: "USER_EMAIL",
      email: currentUser.email,
      uniqueId: "my-unique-identifier",
    });
  } else {
    // No user is logged in
    console.log("No user is logged in.");

    // Display Sign-In Button
    displaySignInButton();

    // setupGoogleSignIn();

    // Hide the userGetContainer if it exists
    // $("#userGetContainer").remove();
  }
}); */

// ------------------------------
// AUTH STATE CHANGE
// ------------------------------

// Listen for auth state changes
onAuthStateChanged(auth, async (user) => {
  const email = user?.email || "user@email.com"; // Use a fallback email

  if (user) {
    console.log(`User: ${email}`);
    displayUserGetData(email);

    // Send message to content script
    // console.log("Sending message to content script with email:", email);
    setTimeout(() => {
      sendMessageToContentScript({
        type: "USER_EMAIL",
        email: email,
      });
    }, 3000);
  } else {
    // No user is logged in
    console.log("No user is logged in.");

    // Display Sign-In Button
    displaySignInButton();
  }
});

// Display automatically for testing
// displayUserGetData();
