// dbUpdateDefaultResume.js

import $ from "jquery";
import { getAuth } from "firebase/auth";
import {
  fetchUserBaseResumes,
  displayUserBaseResumes,
} from "./moduleUserBaseResumes"; // Import these functions if they're not already globally accessible

// Set up Firebase Authentication reference
const auth = getAuth(); // Get the Firebase Auth instance

export function setupSetDefaultResumeButtons() {
  $(".set-default-btn").each(function () {
    $(this).on("click", async function () {
      const docId = $(this).attr("data-doc-id");
      const user = auth.currentUser;
      if (user) {
        const userEmail = user.email;

        // Call the function to send the set default base resume request webhook
        const response = await sendUpdateDefaultBaseResumeWebhook(
          docId,
          userEmail
        );

        // Handle the response from the webhook
        if (response) {
          // console.log("Webhook response:", response); // Now this logs the actual response from the webhook
          const userBaseResumes = await fetchUserBaseResumes(user.email);
          displayUserBaseResumes(userBaseResumes); // Display the updated table
        } else {
          console.error("Set default base resume webhook failed.");
        }
      } else {
        console.error("No authenticated user found.");
      }
    });
  });
}

const webhookUrl = process.env.WEBOOK_UPDATE_DEFAULT_RESUME;

// Function to send the set default resume webhook to n8n
async function sendUpdateDefaultBaseResumeWebhook(docId, userEmail) {
  try {
    const response = await fetch(webhookUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        docId: docId,
        userEmail: userEmail,
      }),
    });

    if (response.ok) {
      const responseData = await response.json(); // Parse JSON response
      console.log(webhookUrl);
      return responseData; // Return the response data
    } else {
      console.log(webhookUrl);
      console.error(
        "Failed to send set default base resume webhook. Status: ",
        response.status
      );
      return null; // Webhook failed
    }
  } catch (error) {
    console.log(webhookUrl);
    console.error("Error sending set default base resume webhook:", error);
    return null; // Webhook failed due to an error
  }
}
