// authUserLogout.js

import { getAuth, signOut } from "firebase/auth";

// Update the UI state after logout
function updateUIAfterLogout() {
  /* const signInButtonsDiv = document.getElementById("loginButtonContainer");
  const userGetDiv = document.getElementById("userGetContainer");
  const logoutButton = document.getElementById("logoutButtonContainer"); */

  console.log("No user logged in.");
  /* signInButtonsDiv.style.display = "block";
  userGetDiv.style.display = "none";
  logoutButton.style.display = "none"; */
}

// Handle the logout process
export function handleLogout() {
  const auth = getAuth();
  signOut(auth)
    /* .then(() => {
      console.log("User signed out.");
      updateUIAfterLogout();
    }) */
    .catch((error) => {
      console.error("Error signing out:", error);
    });
}

// Setup event listener for logout
export function setupLogoutListener() {
  document.getElementById("logout").addEventListener("click", handleLogout);
  // console.log("Logout button listener initialized.");
}

// Listener with check
/* export function setupLogoutListener() {
  const logoutButton = document.getElementById("logout");
  if (logoutButton) {
    logoutButton.addEventListener("click", handleLogout);
  } else {
    // console.error("Delete account button not found in the DOM");
  }
} */
