// authWebhooks.js

import $ from "jquery";

// -------------------------
// MODAL: SETTING UP USER ACCOUNT
// -------------------------

export function showAccountSetupModal() {
  // Create modal background with blur and opacity
  $("body").append(`
    <div id="modal-background" style="
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(5px);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    ">
      <div id="modal-window" style="
        width: 300px;
        height: 150px;
        background-color: #1453b8;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        padding: 20px;
      ">
        Setting up user account...
      </div>
    </div>
  `);
}

export function hideAccountSetupModal() {
  // Remove the modal from the DOM
  $("#modal-background").remove();
}

// -------------------------
// WEBHOOK: SIGN-UP TRIGGER
// -------------------------

export async function triggerSignUpWorkflow(userInfo) {
  const webhookUrl = process.env.WEBHOOK_SIGN_UP;

  // Show modal
  showAccountSetupModal();

  try {
    const response = await fetch(webhookUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userInfo),
    });

    if (!response.ok) {
      throw new Error("Failed to trigger the signup workflow.");
    }

    console.log("Signup workflow triggered successfully.");

    // Wait for 2 seconds, hide the modal, and reload the page
    setTimeout(() => {
      hideAccountSetupModal();
      window.location.reload();
    }, 1500);
  } catch (error) {
    // Hide the modal in case of an error
    hideAccountSetupModal();
    console.error("Error in sign-up workflow:", error);
  }
}
